import {
  apiBrands,
  apiGeographyMentions,
  apiGeographySources,
  apiOrganizations,
  apiPersons,
  apiPools,
  apiProducts,
  apiSources,
  apiThemes,
  apiTypes,
} from '../../api/api'

export const priorityStatuses = {
  1: 'Мониторинг для проектов',
  2: 'Общая индексация',
  3: 'Не индексируется',
}

export const operation = {
  1: 'или',
  2: 'и',
  3: 'исключить',
}

export const EXTERNAL_FILES_BASE_PATH = '/zagruzhennye_fajly'

export const sortKeyValues = {
  1: {
    bd: 'title',
    front: (
      <>
        "<b>по заголовкам</b> - От А до Я"
      </>
    ),
  },
  '-1': {
    bd: '-title',
    front: (
      <>
        "<b>по заголовкам</b> - От Я до А"
      </>
    ),
  },

  2: {
    bd: 'pub_time',
    front: (
      <>
        "<b>по дате и времени</b> - По возрастанию"
      </>
    ),
  },
  '-2': {
    bd: '-pub_time',
    front: (
      <>
        "<b>по дате и времени</b> - По убыванию"
      </>
    ),
  },

  '--3': {
    bd: '-host__source_geography__full_title',
    front: (
      <>
        "<b>по регионам</b> - От А до Я (Москва сначала)"
      </>
    ),
  },
  3: {
    bd: 'host__source_geography__title',
    front: (
      <>
        "<b>по регионам</b> - От А до Я"
      </>
    ),
  },
  '-3': {
    bd: '-host__source_geography__title',
    front: (
      <>
        "<b>по регионам</b> - От Я до А"
      </>
    ),
  },

  4: {
    bd: 'host__official_title',
    front: (
      <>
        "<b>по названию СМИ</b> - От А до Я"
      </>
    ),
  },
  '-4': {
    bd: '-host__official_title',
    front: (
      <>
        "<b>по названию СМИ</b> - От Я до А"
      </>
    ),
  },

  5: {
    bd: 'authors__last_name',
    front: (
      <>
        "<b>по автору</b> - От А до Я"
      </>
    ),
  },
  '-5': {
    bd: '-authors__last_name',
    front: (
      <>
        "<b>по автору</b> - От Я до А"
      </>
    ),
  },

  6: {
    bd: 'host__source_pull__title',
    front: (
      <>
        "<b>по пулам СМИ</b> - От А до Я"
      </>
    ),
  },
  '-6': {
    bd: '-host__source_pull__title',
    front: (
      <>
        "<b>по пулам СМИ</b> - От Я до А"
      </>
    ),
  },

  '-7': {
    bd: '-is_negative_tonalities',
    front: (
      <>
        "<b>по тональности</b> - По возрастанию тональности"
      </>
    ),
  },
  7: {
    bd: 'is_negative_tonalities',
    front: (
      <>
        "<b>по тональности</b> - По убыванию тональности"
      </>
    ),
  },

  8: {
    bd: 'theme_tonalities__theme__full_title',
    front: (
      <>
        "<b>по тематикам 1 уровня</b> - От А до Я"
      </>
    ),
  },
  '-8': {
    bd: '-theme_tonalities__theme__full_title',
    front: (
      <>
        "<b>по тематикам 1 уровня</b> - От Я до А"
      </>
    ),
  },

  '--9': {
    bd: '-host__source_type__full_title',
    front: (
      <>
        "<b>по типам источников</b> - По убыванию (Фед пресса сначала)"
      </>
    ),
  },
  9: {
    bd: 'host__source_type__title',
    front: (
      <>
        "<b>по типам источников</b> - По возрастанию"
      </>
    ),
  },
  '-9': {
    bd: '-host__source_type__title',
    front: (
      <>
        "<b>по типам источников</b> - По убыванию "
      </>
    ),
  },
}

export const templatesDoc = [
  {
    id: 1,
    title: 'Шаблон 1',
    link: '',
    icon: 'bx-menu',
  },
  {
    id: 2,
    title: 'Шаблон 2',
    link: '',
    icon: 'bx-link-alt',
  },
  {
    id: 3,
    title: 'Шаблон 3',
    link: '',
    icon: 'bx-droplet',
  },
  {
    id: 4,
    title: 'Шаблон 4',
    link: '',
    icon: 'bx-bulb',
  },
  {
    id: 5,
    title: 'Шаблон 5',
    link: '',
    icon: 'bx-star',
  },
]
export const templatesExcel = [
  {
    id: 1,
    title: 'Шаблон 1',
    link: '',
    icon: '',
  },
]
export const templatesPdf = [
  {
    id: 1,
    title: 'Шаблон 1',
    link: '',
    icon: '',
  },
]
export const templatesHtml = [
  {
    id: 1,
    title: 'Шаблон 1',
    link: '',
    icon: '',
  },
]
export const DAY = 'day'
export const WEEK = 'week'
export const MONTH = 'month'
export const QUARTER = 'quarter'
export const YEAR = 'year'

export const DAILY = 'daily'
export const WEEKLY = 'weekly'
export const MONTHLY = 'monthly'
export const QUARTERLY = 'quarterly'
export const YEARLY = 'yearly'

export const MASS_MEDIA = 'mass media'
export const SOCIAL_MEDIA = 'social media'

export const diapasonCharts = [
  { id: DAY, title: 'День' },
  { id: WEEK, title: 'Неделя' },
  { id: MONTH, title: 'Месяц' },
  { id: QUARTER, title: 'Квартал' },
  { id: YEAR, title: 'Год' },
]

export const mainLocationCharts = [
  { id: DAILY, title: 'Ежедневные', hasLocation: true },
  { id: WEEKLY, title: 'Еженедельные', hasLocation: true },
  { id: MONTHLY, title: 'Ежемесячные', hasLocation: true },
  { id: QUARTERLY, title: 'Ежеквартальные', hasLocation: true },
  { id: YEARLY, title: 'Ежегодные', hasLocation: true },
]

export const locationCharts = [
  { id: MASS_MEDIA, title: 'СМИ' },
  { id: SOCIAL_MEDIA, title: 'Социальных медиа' },
]

export const tonalityItemsColors = {
  0: { value: '#bebdbe', label: 'any' },
  1: { value: '#28C76F', label: 'positive' },
  2: { value: '#FF9F43', label: 'neutral' },
  3: { value: '#EA5455', label: 'negative' },
  4: { value: '#4B4B4B', label: 'conflict' },
  5: { value: '#bebdbe', label: 'without' },
}

export const tonalityItems = [
  {
    value: 0,
    label: 'Любая',
    color: tonalityItemsColors['0'].label,
  },
  {
    value: 1,
    label: 'Позитив',
    color: tonalityItemsColors['1'].label,
  },
  {
    value: 2,
    label: 'Нейтрал',
    color: tonalityItemsColors['2'].label,
  },
  {
    value: 3,
    label: 'Негатив',
    color: tonalityItemsColors['3'].label,
  },
  {
    value: 4,
    label: 'Кризис/Конфликт',
    color: tonalityItemsColors['4'].label,
  },
]

export const tonalityValue = {
  0: 'Любая',
  1: 'Позитив',
  2: 'Нейтрал',
  3: 'Негатив',
  4: 'Кризис/Конфликт',
  5: 'Без тональности',
}

export const chartColors = [
  '#8884d8',
  '#67b7dc',
  '#6794dc',
  '#6771dc',
  '#8067dc',
  '#a367dc',
  '#c767dc',
  '#dc67ce',
  '#dc67ab',
  '#dc6788',
  '#dc6967',
  '#dc8c67',
  '#dcaf67',
  '#dcd267',
  '#a0dc67',
  '#7ddc67',
  '#67dc75',
  '#67dc98',
  '#67dcbb',
  '#67dadc',
  '#0b7d03',
  '#e1d92d',
  '#e17a2d',
  '#ca0101',
]

export const chartTonalitiesColors = [
  '#bebdbe',
  '#28C76F',
  '#FF9F43',
  '#EA5455',
  '#4B4B4B',
  '#bebdbe',
]
export const PIE_CHART_LEGEND = {
  organization: {
    title: 'Организации',
    request: id => apiOrganizations.getOneOrganization(id),
    titleName: 'title',
  },
  person: {
    title: 'Персоны',
    request: id => apiPersons.getOnePerson(id),
    titleName: 'last_name', //todo: change ?
  },
  brand: {
    title: 'Бренды',
    request: id => apiBrands.getOneBrand(id),
    titleName: 'title',
  },
  product: {
    title: 'Продукты',
    request: id => apiProducts.getOneProduct(id),
    titleName: 'title',
  },
  geography_mention: {
    title: 'Географические упоминания',
    request: id => apiGeographyMentions.getOneGeographyMention(id),
    titleName: 'short_title', //todo: change on short_title ??
  },
  theme: {
    title: 'Тематики',
    request: id => apiThemes.getOneTheme(id),
    titleName: 'title',
  },
  geography_sources: {
    title: 'Географические источники',
    request: id => apiGeographySources.getOneGeographySource(id),
    titleName: 'short_title', //todo: change on short_title ??
  },
  source_types: {
    title: 'Типы источников',
    request: id => apiTypes.getOneType(id),
    titleName: 'short_title', //todo: change on short_title ??
  },
  source_pools: {
    title: 'Пулы источников',
    request: id => apiPools.getOnePool(id),
    titleName: 'short_title', //todo: change on short_title ??
  },
  sources: {
    title: 'Источники',
    request: id => apiSources.getOneSource(id),
    titleName: 'official_title', //todo: change on title_for_db ?? много где поле пустое
  },
  authors: {
    title: 'Авторы',
    request: id => apiPersons.getOnePerson(id),
    titleName: 'last_name', //todo: change ?
  },
}

export const LEVEL_TWO_PIE_CHART_LEGEND_ITEMS = [
  'organization',
  'person',
  'brand',
  'product',
  'geography_mention',
  'theme',
]

export const LINE_CHART_LEGEND = {
  organizations: {
    title: 'Организации',
  },
  persons: {
    title: 'Персоны',
  },
  brands: {
    title: 'Бренды',
  },
  products: {
    title: 'Продукты',
  },
  geography_mentions: {
    title: 'Географические упоминания',
  },
  themes: {
    title: 'Тематики',
  },
  geography_sources: {
    title: 'Географические источники',
  },
  source_types: {
    title: 'Типы источников',
  },
  source_pools: {
    title: 'Пулы источников',
  },
  sources: {
    title: 'Источники',
  },
  authors: {
    title: 'Авторы',
  },
}
