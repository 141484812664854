import axios from 'axios'
import { checkToken, onLogout } from '../auth'
import { toast } from 'react-toastify'

// axios.defaults.baseURL = `${process.env.REACT_APP_API_DOMAIN}/api/v1`;
// axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}/api/v1`,

  //todo need this header ????
  // withCredentials: true,

  //todo: перед деплоем на ПРОД withCredentials: true, на ДЕВ - withCredentials: false
})
export const axiosAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}/api/v1`
})

// export const baseApiDomain = `${process.env.REACT_APP_API_DOMAIN}`

const setCommonHeaders = headers => {
  headers['Content-Type'] = 'application/json'
}

const setFileType = headers => {
  headers['Content-Type'] = 'multipart/form-data'
}

const setAuthHeaders = (headers, token) => {
  headers['Authorization'] = 'Bearer ' + token
}

axiosInstance.interceptors.request.use(
  async config => {
    // console.log('request',{config});
    setCommonHeaders(config.headers)

    if (config?._isFile) {
      setFileType(config.headers)
    }
    const token = await checkToken()
    
    if (token) {
      setAuthHeaders(config.headers, token)
    }

    return config
  },
  error => {
    // captureException(error);
    return Promise.reject(error)
  },
)
// }, [])

// useEffect(() => {
axiosInstance.interceptors.response.use(
  response => response?.data,
  async error => {
    // captureException(error);

    // console.log('response interceptor')
    // console.log(error)

    const request = error.config

    if (error?.response && error?.response?.status === 401) {
      const errors = error.response?.data?.errors || []
      const userDoesNotExist = errors.some(error => error.code === 'authentication_failed')

      if (userDoesNotExist) {
        // axios.defaults.headers.common['Authorization'] = ''
        axiosInstance.defaults.headers.common['Authorization'] = ''
        return onLogout('ban')
      }
    }

    // one-time retry of the request, if something is wrong with the access token
    if (error?.response && error?.response?.status === 401 && !request._retry) {
      request._retry = true

      const token = await checkToken()

      if (token) {
        // setAuthHeaders(axios.defaults.headers.common, token)
        setAuthHeaders(axiosInstance.defaults.headers.common, token)
      }

      // return axios(request)
      return axiosInstance(request)
    }

    if (error?.response && error?.response?.status === 403) {
      // axiosInstance.defaults.headers.common['Authorization'] = '';
      toast.error('У вас недостаточно прав для выполнения данного действия.')
      // return onLogout('ban');
    }

    return Promise.reject(error)
  },
)
export default axiosInstance
