import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
// import moment from "moment/moment";
import { apiLogin } from './api';

const isTokenFresh = (tokenExpTime) => {
  // console.log(new Date(tokenExpTime * 1000) - new Date(Date.now()))
  return new Date(tokenExpTime * 1000) - new Date(Date.now()) > 10000;
};

// const goToLogin = (reason) => {
//   const reasonParam = reason ? `?reason=${reason}` : "";
//   Router.push(`/login${reasonParam}`);
// };

// export const redirectUserToAuthForm = (type = "login") => {
// 	Router.replace('/');
// 	// Cookies.set("modal", type);
// };

const setUserType = (accessToken) => {
  const userRole = jwtDecode(accessToken).role;
  if (userRole === 'admin') {
    Cookies.set('admin', true);
  }
};

const setNewAccess = async (refresh) => {
  
  const tokens = await apiLogin.refreshToken(refresh);
  const userAccount = jwtDecode(tokens.access).user_id;
  Cookies.set('access', tokens.access);
  Cookies.set('userAccount', userAccount);

  setUserType(tokens.access);
};

export const onLogin = (accessToken, refreshToken) => {
  const accessTokenExpTime = jwtDecode(accessToken).exp;
  const userAccount = jwtDecode(accessToken).user_id;

  Cookies.set('access', accessToken, { expires: accessTokenExpTime });
  Cookies.set('userAccount', userAccount, { expires: accessTokenExpTime });
  //R
  Cookies.set('isLoggedIn', 1, { expires: accessTokenExpTime });

  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('isUserLoggedIn', true);

  setUserType(accessToken);
};

export const onLogout = (authFormType) => {
  Cookies.remove('access');
  Cookies.remove('isLoggedIn');
  Cookies.remove('admin');
  Cookies.remove('userAccount');

  localStorage.removeItem('refreshToken');
  localStorage.setItem('isUserLoggedIn', false);
  // goToLogin(reason);
  if (authFormType) {
    // redirectUserToAuthForm(authFormType);
  }
};

const handleSessionExpiration = () => {
  onLogout('login');
  // console.log("Your session has expired, please login again.");
};

export const checkToken = async () => {
  // console.log("checking tokens");
  const accessToken = Cookies.get('access');
  const refreshToken = localStorage.getItem('refreshToken');

  // когда нет рефреша
  if (!refreshToken) {
    // console.log("no refresh");
    return onLogout('login');
  }

  // когда нет аксеса
  if (!accessToken) {
    // console.log("no access");
    const refreshTokenExpTime = jwtDecode(refreshToken).exp;

    return isTokenFresh(refreshTokenExpTime)
      ? await setNewAccess(refreshToken)
      : handleSessionExpiration();
  }

  // когда аксес есть
  // console.log("access exists");
  const accessTokenExpTime = jwtDecode(accessToken).exp;

  // когда аксес протух
  if (!isTokenFresh(accessTokenExpTime)) {
    // console.log("access is expired");
    const refreshTokenExpTime = jwtDecode(refreshToken).exp;

    return isTokenFresh(refreshTokenExpTime)
      ? await setNewAccess(refreshToken)
      : handleSessionExpiration();
  }

  // когда аксес нормальный
  // console.log("all is good");
  return accessToken;
};
