import { lazy, StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client';
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ToggleApps } from './components/ToggleApps'

const App = lazy(() => import('./App'))
const ExternalApp = lazy(() => import('./ExternalApp'))

const root = createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <BrowserRouter>
      <Suspense fallback={null}>
        <ToggleApps
          internalApp={<App />}
          externalApp={<ExternalApp/>}
        />
      </Suspense>
    </BrowserRouter>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
