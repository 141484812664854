import classNames from 'classnames'

export const Loader = ({ className, style }) => (
  <div
    style={style}
    className={classNames('spinner-border', className)}
    role='status'
  >
    <span className='sr-only'>Loading...</span>
  </div>
)
