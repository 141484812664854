import { toast } from 'react-toastify'
import { RubricatorMonitoringRubric } from '../components/treeView/RubricatorMonitoringRubric'
import { Loader } from '../components/loader/Loader'
import { apiParser } from '../api/apiParser'

export const isFunction = val => typeof val === 'function'

/**
 * @description Create an object with keys
 * @param {string[]} keys
 * @param  {*} value
 * @returns {{[p: string]: any}}
 */
export const record = (keys, value) => Object.fromEntries(keys.map((k) => [k, value]))

export const toggle = (isTrue, ifTrue = isTrue, ifFalse = '') => (isTrue ? ifTrue : ifFalse)

export const objSize = obj => {
  let size = 0,
    key
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++
  }
  return size
}

export const isEmpty = any => {
  if (typeof any === 'boolean') return false
  if (typeof any === 'string') return !any?.trim()
  if (typeof any === 'number') return false
  if (Array.isArray(any)) return !any.length
  if (typeof any === 'object') return !objSize(any)
  return !any
}

export const isNotEmpty = any => !isEmpty(any)

export const includeSomeSubstring = (string, arr = []) =>
  arr.some(subStr => string.includes(subStr))

export const findParentItemInTreeView = (arr, elementId, treeView) => {
  const newArr = arr?.map(item => {
    if (+item.key === +elementId) {
      item.nodes = treeView
    } else if (item.is_has_children) {
      findParentItemInTreeView(item.nodes, elementId, treeView)
    }
    return item
  })
  return newArr
}

export const selectThemeColorsCustom = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#5A8DEE90', // for selected option bg-color
    neutral10: '#e9f4ff', // for tags bg-color
    neutral20: '#DFE3E7', // for input border-color
    neutral30: '#DFE3E7', // for input hover border-color
  },
})

//проверяем пересечение массивов для options select
export const checkDefaultMultiSelect = (optionsSelect, defaultValues) => {
  const selectedItemsSet = new Set(defaultValues)

  const filteredOptionsArr = optionsSelect.filter(item => selectedItemsSet.has(item.value))

  return filteredOptionsArr
}

// получаем options для select
export const getOptions = data => {
  return data?.map(item => ({ value: item.id, label: item.title || item.titles }))
}

export const getFullNameFromObj = userData => {
  if (!userData) return '-'
  const { first_name, middle_name, last_name } = userData

  return `${last_name} ${first_name}\u{00A0}${middle_name}`
}

export const getShortFullName = (first_name, middle_name, last_name) => {
  if (isEmpty(last_name)) return `${first_name} ${middle_name}`
  return `${last_name}\u{00A0}${first_name && first_name[0]}.\u{00A0}${
    middle_name && middle_name[0]
  }.`
}

export const getShortFullNameFromObj = userData => {
  if (!userData) return '-'

  const { first_name, middle_name, last_name } = userData

  if (isEmpty(last_name)) return `${first_name}\u{00A0}${middle_name}`

  const getShort = name => toggle(name, `${name[0]}.`)
  return `${last_name}\u{00A0}${getShort(first_name)}\u{00A0}${getShort(middle_name)}`
}
export const increaseUniq = uniq => uniq + 1
export const debounce = function (func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const getPersonOptions = data => {
  return data?.map(item => {
    const { id, full_name, position, organization } = item
    return {
      value: id,
      label: `${full_name ? full_name : getFullNameFromObj(item)}${
        position ? ` , ${position}` : ''
      }${organization ? ` , ${organization}` : ''}`,
    }
  })
}

/**
 * Generates the class for the loader.
 *
 * @param {boolean} hide - Whether to hide the loader or not.
 * @param {Object} options - The options for the loader.
 * @param {'small' | 'large'} options.size - The size of the loader. Can be 'small' or 'large'.
 * @return {string} The class name for the loader.
 */
export const loader = (hide, { size } = {}) => {
  const finalStyle = `${toggle(size === 'small', 'small ')}${toggle(
    size === 'large',
    'large ',
  )}${toggle(!hide, 'l-show')}`

  return `c-loading ${finalStyle}`
}

export const getShortText = (text, textLength) => {
  return text.slice(0, textLength) + '...'
}

export function createFormData(data) {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value)
  })
  return formData
}

export const getDocxFile = async articlesIDS => {
  if (articlesIDS.length === 0) {
    toast.error('Выберите статьи для формирования отчета!')
    return
  }

  const submitData = {
    articles_id: articlesIDS,
    structure: 'TABLE_OF_CONTENT',
    heading_1: {
      list_elements: ['title', 'host.source_geography.title', 'pub_time'],
      font_name: 2,
      font_size: 9,
      font_bold: true,
      font_italic: true,
      font_underline: false,
      alignment: 'CENTER',
      line_spacing_rule: 'SINGLE',
      font_color: 8,
    },
    heading_2: {
      list_elements: ['host.official_title', 'authors'],
      font_name: 2,
      font_size: 8,
      font_bold: false,
      font_italic: true,
      font_underline: false,
      alignment: 'RIGHT',
      line_spacing_rule: 'SINGLE',
      font_color: 1,
    },
    heading_3: {
      list_elements: ['paragraphs'],
      font_name: 2,
      font_size: 7,
      font_bold: false,
      font_italic: true,
      font_underline: false,
      alignment: 'JUSTIFY',
      line_spacing_rule: 'SINGLE',
      font_color: 1,
    },
    heading_4: {
      list_elements: ['title'],
      font_name: 2,
      font_size: 6,
      font_bold: false,
      font_italic: true,
      font_underline: false,
      alignment: 'JUSTIFY',
      line_spacing_rule: 'SINGLE',
      font_color: 1,
    },
    heading_5: {
      list_elements: ['title'],
      font_name: 2,
      font_size: 5,
      font_bold: false,
      font_italic: true,
      font_underline: false,
      alignment: 'JUSTIFY',
      line_spacing_rule: 'SINGLE',
      font_color: 1,
    },
    heading_6: {
      list_elements: ['title'],
      font_name: 2,
      font_size: 4,
      font_bold: false,
      font_italic: true,
      font_underline: false,
      alignment: 'LEFT',
      line_spacing_rule: 'SINGLE',
      font_color: 1,
    },
    body_text: {
      list_elements: ['text'],
      font_name: 1,
      font_size: 3,
      font_bold: false,
      font_italic: false,
      font_underline: false,
      alignment: 'LEFT',
      line_spacing_rule: 'SINGLE',
      font_color: 1,
    },
    hyperlink: {
      list_elements: ['url'],
      font_name: 1,
      font_size: 3,
      font_bold: false,
      font_italic: false,
      font_underline: true,
      alignment: 'LEFT',
      line_spacing_rule: 'SINGLE',
      font_color: 10,
    },
    hyperlink_followed: {
      list_elements: ['text'],
      font_name: 1,
      font_size: 3,
      font_bold: false,
      font_italic: false,
      font_underline: true,
      alignment: 'LEFT',
      line_spacing_rule: 'SINGLE',
      font_color: 9,
    },
  }

  // const colors = await apiParser.getFontColors()
  // const sizes = await apiParser.getFontSizes()
  // const names = await apiParser.getFontNames()

  try {
    // const data =
      await apiParser.createDocxReport(submitData)
    // const fileLink = data?.file
  } catch (e) {
    toast.error(e?.response?.data?.errors?.[0]?.detail || e?.message)
  }
}

export const getMonitoringTreeItems = (monitorings, openModalToEdit, openModalToAddSubrubric) => {
  const monitoringsTreeView =
    monitorings?.map(monitoring => ({
      key: `rubric_${monitoring.parent_project}_${monitoring.parent}_${monitoring.id}`,
      label: (
        <RubricatorMonitoringRubric
          item={monitoring}
          editHandler={openModalToEdit}
          addHandler={openModalToAddSubrubric}
        />
      ),
      is_has_children: monitoring.is_has_children,
      nodes: monitoring.is_has_children ? [{ key: new Date(), label: <Loader /> }] : null,
    })) || []

  return monitoringsTreeView
}

export const updateNodesWithMonitoring = (arr, parentId, parentType, monitoringsTreeView) => {
  const newArr = arr?.map(project => {
    let treeKey = project.key

    let parentProjectIdFromTree = project.key
    let parentRubricIdFromTree
    let rubricIdFromTree

    if (treeKey.toString()?.includes('withMonitoring')) {
      const treeKeyArr = treeKey.split('_')
      parentProjectIdFromTree = +treeKeyArr[1]
    }

    if (treeKey.toString()?.includes('rubric')) {
      const treeKeyArr = treeKey.split('_')
      parentProjectIdFromTree = +treeKeyArr[1]
      parentRubricIdFromTree = +treeKeyArr[2]
      rubricIdFromTree = +treeKeyArr[3]
      // treeKey = +treeKey.split('_')[1]
    }

    if (
      /*project.is_has_rubricators_monitorings &&*/ parentType === 'project'
        ? parentProjectIdFromTree === parentId
        : rubricIdFromTree === parentId
    ) {
      project.is_has_rubricators_monitorings = true
      project.is_has_children = true
      project.nodes = monitoringsTreeView
    } else if (project.is_has_children) {
      updateNodesWithMonitoring(project.nodes, parentId, parentType, monitoringsTreeView)
    }
    return project
  })

  return newArr
}

export const checkDefaultUrlTonality = (data, searchParams, setSearchParams, paramValue) => {
  if (data) {
    if (
      data?.brand_tonalities?.length ||
      data?.geography_mention_tonalities?.length ||
      data?.organization_tonalities?.length ||
      data?.person_tonalities?.length ||
      data?.product_tonalities?.length ||
      data?.theme_tonalities?.length
    ) {
      if (
        data.brand_tonalities.filter(item => item.tonality === 5).length !== 0 ||
        data.geography_mention_tonalities.filter(item => item.tonality === 5).length !== 0 ||
        data.organization_tonalities.filter(item => item.tonality === 5).length !== 0 ||
        data.person_tonalities.filter(item => item.tonality === 5).length !== 0 ||
        data.product_tonalities.filter(item => item.tonality === 5).length !== 0 ||
        data.theme_tonalities.filter(item => item.tonality === 5).length !== 0
      ) {
        searchParams.set(paramValue, 'without_tonality')
        setSearchParams(searchParams)
      } else {
        searchParams.set(paramValue, 'with_tonality')
        setSearchParams(searchParams)
      }
    } else {
      searchParams.set(paramValue, 'with_tonality')
      setSearchParams(searchParams)
    }
  }
}

// цвета пилзов для значений меток/проектов/тональностей
// Brand: light-warning,
// geography-mention:  light-success,
// organization: light-danger,
// person: light-secondary,
// product: success,
// theme: info,
// project: light-primary,
// marks: secondary

export const setStartOfDay = date => {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

export const setFinishOfDay = date => {
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(59)

  return date
}

export const setUTCStartOfDay = date => {
  const timezoneOffset = date.getTimezoneOffset()

  date.setHours(0 - timezoneOffset / 60, 0, 0, 0)
  return date
}

export const setUTCFinishOfDay = date => {
  date.setHours(23, 59, 59, 999)
  return date
}

export function getDateSeveralDaysBeforeNow(days) {
  const currentDate = new Date()
  const daysBeforeDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
  return daysBeforeDate
}

export function getDateSeveralDaysAfterDate(date, days) {
  const currentDate = new Date(date)
  const daysAfterDate = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000)
  return daysAfterDate
}

export function getDateSeveralMonthBeforeNow(months) {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - months)

  return new Date(currentDate)
}

export function getDateSeveralMonthAfterDate(date, months) {
  const currentDate = new Date(date)
  currentDate.setMonth(currentDate.getMonth() + months)

  return currentDate
}

export const getRandomColor = () => Math.floor(Math.random() * 16777215).toString(16)
