import { Link } from 'react-router-dom'

export const RubricatorMonitoringRubric = ({ item, editHandler, addHandler }) => {
  const reversedProjectsParents = item.projects_parents.reverse().slice(0)
  item.projects_parents.reverse()

  const reversedMonitoringParents = item.parents.reverse().slice(0)
  item.parents.reverse()
  const reversedMonitoringParentsKey = reversedMonitoringParents.map(
    (_item, index, arr) =>
      `rubric_${item.parent_project}_${index === 0 ? 'null' : arr[index - 1]}_${_item}`,
  )

  return (
    <div
      className='d-flex flex-wrap justify-content-between align-items-center flex-grow-1'
      id={`treeRoot/${reversedProjectsParents[0]}/withMonitoring_${item.parent_project}${
        reversedMonitoringParentsKey.length === 0
          ? `/rubric_${item.parent_project}_null_${item.id}`
          : '/' +
            reversedMonitoringParentsKey.join('/') +
            `/rubric_${item.parent_project}_${item.parent}_${item.id}`
      }`}
    >
      <span>{item.title}</span>
      <div className='d-flex flex-wrap align-items-center'>
        <button
          className='btn btn-gray btn-sm mr-50'
          onClick={() => addHandler(item, item.parent_project)}
        >
          +
        </button>
        <button
          className='btn btn-gray btn-sm'
          onClick={() => editHandler(item, item.parent_project)}
        >
          ред
        </button>
        {item?.indexing_monitoring?.length ? (
          <Link
            className='btn btn-light-success btn-sm ml-25'
            to={`/monitoring/rubrikator_monitoringov/izmenit_indeksaciju_rubriki/${item.id}/${item.indexing_monitoring[0]}`}
          >
            Изменить индексацию рубрики
          </Link>
        ) : (
          <Link
            className='btn btn-gray btn-sm ml-25'
            to={`/monitoring/rubrikator_monitoringov/proindeksirovat_v_rubrik/${item.id}`}
          >
            Проиндексировать рубрику
          </Link>
        )}{' '}
      </div>
    </div>
  )
}
